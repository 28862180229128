$publicPath:'';

.jianpai-item {
  background: rgba(9, 166, 102, 0.1);
  height: 102px;
  position: relative;
  margin-bottom: 15px;

  .arrow {
    position: absolute;
    top: 50%;
    right: 20px;
    margin-top: -14px;
    font-size: 28px;
    color: #999999;
    cursor: pointer;
  }
  .jianpai-item-col {
    float: left;
    width: 33%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    .title {
      color: rgba(9, 166, 102, 1);
    }
    .title-label {
      color: #999999;
      font-size: 12px;
    }
    .label {
      color: #999999;
      font-size: 12px;
      margin-top: 20px;
    }
    &.jianpai-item-col1 {
      width: 30%;
    }
    &.jianpai-item-col2 {
      width: 25%;
    }
    &.jianpai-item-col3 {
      width: 25%;
    }
  }
}
